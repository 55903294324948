import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useTranslation } from 'hooks';
import { RowRight } from 'resources';

const Icon = dynamic(() => import('components/Icon'));
const Link = dynamic(() => import('components/Link'));

interface ButtonLinkProp {
  href: string;
  className?: string;
  title?: string;
  visibleIcon?: boolean;
}
const ButtonLink: React.FC<ButtonLinkProp> = ({ href, title, className, visibleIcon = false }) => {
  const { t } = useTranslation(['common']);
  return (
    <div className={cn('box-news', className)}>
      <Link href={href} className="box-news__link">
        <p className="box-news__subtitle">{title ? title : t('allNews')}</p>
        {visibleIcon && (
          <div className="box-news__button">
            <Icon component={RowRight} />
          </div>
        )}
      </Link>
    </div>
  );
};

export default ButtonLink;
